.op-app-help
  &--icon
    &::before
      display: flex
      justify-content: center
      align-items: center
      border: 2px solid var(--header-item-font-color)
      border-radius: 50%
      width: 26px
      height: 26px
      padding: 1px 8px 0 8px
