/*
  Odds and ends.
*/

/// @Foundation.settings
// Extras
$closebutton-position: (top right) !default;
$closebutton-size: 2em !default;
$closebutton-lineheight: 0.5 !default;
$closebutton-color: #999 !default;
$closebutton-color-hover: #333 !default;

$thumbnail-padding: 0.5rem !default;
$thumbnail-shadow: 0 3px 15px rgba(black, 0.25) !default;
///

// A basic close button. They pin to the corner of the thing they're inside.
%close-button {
  $x: nth($closebutton-position, 1);
  $y: nth($closebutton-position, 2);

  position: absolute;
  color: $closebutton-color;
  #{$x}: $global-padding;
  #{$y}: $global-padding;
  font-size: $closebutton-size;
  line-height: $closebutton-lineheight;
  cursor: pointer;

  &:hover {
    color: $closebutton-color-hover;
  }
}

// Make your images fancy-like.
%thumbnail {
  padding: $thumbnail-padding;
  box-shadow: $thumbnail-shadow;
}

@include exports(extras) {
  .close-button {
    @extend %close-button;
  }
  .thumbnail {
    @extend %thumbnail;
  }
  ul.thumbnails > li {
    margin-bottom: 1rem;
    
    a { display: block; }
    img { @extend %thumbnail; }
  }
}
