.op-avatar
  border-radius: var(--user-avatar-border-radius)
  width: var(--user-avatar-width)
  // min-width was added because the avatar was getting squished as a flex-child
  min-width: var(--user-avatar-width)
  height: var(--user-avatar-height)
  color: white
  display: inline-block
  text-align: center
  vertical-align: middle
  cursor: inherit
  user-select: none
  background-color: var(--user-avatar-default-bg-color)
  // Default size
  line-height: 36px
  font-size: 16px

  // Fallback avatar if no img tag gets rendered
  &--fallback
    max-width: none

  &_mini
    border-radius: var(--user-avatar-mini-border-radius)
    width: var(--user-avatar-mini-width)
    min-width: var(--user-avatar-mini-width)
    height: var(--user-avatar-mini-height)
    line-height: var(--user-avatar-mini-height)
    font-size: 10px

  &_medium
    border-radius: var(--user-avatar-medium-border-radius)
    width: var(--user-avatar-medium-width)
    min-width: var(--user-avatar-medium-width)
    height: var(--user-avatar-medium-height)
    line-height: var(--user-avatar-medium-height)
    font-size: 13px

  // Different border styling for placeholders
  &_placeholder-user
    border: 1px dashed
    background: none

  // Different border styling for groups
  &_group
    border: 1px solid white
    box-shadow: 4px 0px 0px -1px #cccccc
