// Project status widget
.ng-select
  &.project-status
    min-width: 200px
    max-width: 250px
    .ng-select-container
      min-height: 56px !important
      height: 56px !important

    .ng-input
      height: 44px
      line-height: 44px
      input
        font-weight: bold
        font-size: 16px
        text-transform: uppercase

    .ng-clear-wrapper
      margin-left: 11px

.project-status--bulb
  display: inline-block
  width: 40px
  height: 40px
  border-radius: 50%
  border-width: 10px
  border-style: solid
  margin-right: 7px
  vertical-align: middle
  &.-not-set
    // Have to use uppercase functions to make sass ignore them :-/
    // https://github.com/sass/node-sass/issues/2251
    border-color: RGB(var(--project-status-gray))
    background-color: RGBA(var(--project-status-gray), 0.3)
  &.-off-track
    border-color: var(--project-status-red)
    background-color: RGBA(var(--project-status-gray), 0.3)
  &.-at-risk
    border-color: var(--project-status-orange)
    background-color: RGBA(var(--project-status-gray), 0.3)
  &.-on-track
    border-color: var(--project-status-green)
    background-color: RGBA(var(--project-status-gray), 0.3)
  &.-not-started
    border-color: var(--project-status-light-blue)
    background-color: RGBA(var(--project-status-gray), 0.3)
  &.-finished
    border-color: var(--project-status-deep-green)
    background-color: RGBA(var(--project-status-gray), 0.3)
  &.-discontinued
    border-color: var(--project-status-deep-blue)
    background-color: RGBA(var(--project-status-gray), 0.3)

  &.-inline
    height: 24px
    width: 24px
    border-width: 6px

.project-status--container
  display: inline-block

.project-status--display-field
  height: 56px
  .status.-editable
    line-height: 50px
    border-radius: 4px
  &:hover
    cursor: pointer

.project-status--name
  text-transform: uppercase
  font-weight: bold

  &.-not-set
    color: var(--project-status-gray)
  &.-off-track
    color: var(--project-status-red)
  &.-at-risk
    color: var(--project-status-orange)
  &.-on-track
    color: var(--project-status-green)
  &.-not-started
    color: var(--project-status-light-blue)
  &.-finished
    color: var(--project-status-deep-green)
  &.-discontinued
    color: var(--project-status-deep-blue)

.project-status--pulldown-icon
  font-size: 10px
  vertical-align: middle
  &:before
    color: lightgrey

.project-status--explanation
  margin-top: 9px
