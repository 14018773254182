.op-table
  border-collapse: collapse
  width: 100%

  &--cell
    padding: 12px 16px
    border: 1px solid #cccccc
    text-align: center

    &_heading
      background-color: #f3f3f3
      font-weight: bold
      text-align: left

    &_soft-heading
      background-color: transparent
      text-align: left
      font-weight: normal
