.op-activity-days
  display: flex
  flex-direction: column
  margin: 0

  &--header
    @include spot-body-small (bold)
    padding: 0
    padding-bottom: $spot-spacing-0_5
    margin-bottom: $spot-spacing-1
    color: $spot-color-basic-gray-1
