//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2023 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++


#wrapper
  @include default-transition
  display: grid
  grid-template-rows: auto 1fr

#main
  display: grid
  grid-template-columns: auto 1fr
  overflow: auto

  &.nomenus
    padding-bottom: 0
    overflow: hidden

  &.nosidebar
    grid-template-columns: auto

#content-wrapper
  @include default-transition
  @include styled-scroll-bar
  margin: 0 0 0 0
  padding: 0px
  // Needed for Safari
  height: calc(100vh - var(--header-height))
  overflow-y: auto
  overflow-x: hidden
  background-color: #fff
  position: relative

  &.nosidebar
    margin-left: 0

  &.nomenus
    margin:     0
    padding:    0

#content
  padding: 0
  padding: 10px 20px
  margin: 0
  width: 100%
  z-index: 10
  background-color: $body-background

.content-overlay
  display: none

.-draggable
  cursor: grab
